import React from 'react'; // eslint-disable-line import/no-extraneous-dependencies
import Helmet from 'react-helmet';
import Link from 'gatsby-link';
import { graphql } from 'gatsby';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Page from '../components/Page';
import LogosBlock from '../components/Logos/block';
import Location from '../components/Location';

import styles from './about-us.module.css';

const AboutUsPage = ({ data }) => (
  <div>
    <Helmet
      title={`${data.site.siteMetadata.title} - About us`}
      meta={[
        {
          name: 'description',
          content:
            'To learn what we don’t know. To solve hard challenges. To enjoy the journey.',
        },
        {
          name: 'keywords',
          content:
            'javascript, js, consulting, big data, hadoop, spark, machine learning, tensor flow, react, angular, web, html, css, design, ux',
        },
      ]}
    >
      <html lang="sv" />
      <link rel="icon" href="/favicon.ico?v=2" />
    </Helmet>
    <div>
      <Header className={styles.headerBackground} />
      <Page bgColor="#F8F6FA">
        <section>
          <h1>About North Link</h1>
          <h2>Pushing boundaries - together</h2>
          <p>
            To learn what we don’t know. To solve hard challenges. To enjoy the journey.
          </p>
        </section>
        <main className={styles.main}>
          <div className={styles.line} />
          <section>
            <h4>About us</h4>
            <p>A feeling. That was the inspiration for our company.</p>
            <p>
              If you are a software developer you might know the feeling that we
              are talking about. The enjoyment of solving complex problems,
              wired in, with a keyboard in front of you.
            </p>
            <p>
              The question of what makes us enjoy writing software is the
              beginning of the story of North Link. A group of friends with the
              idea of building a company where the employees are the company’s
              greatest asset.
            </p>
            <p>
              As the story unfolded we continued to work with hard problems.
              Problems that kept us on our toes. The kind of problems where we
              constantly have to learn new things, work together and keep
              innovating. The kind of problems that keep us inspired.
            </p>
            <p>
              This pursuit is our mission. To build a company that values
              knowledge. A company where innovation is celebrated. A company
              where we constantly strive to enjoy writing software.
            </p>
            <p>So that we can push boundaries - together.</p>
          </section>
          <section>
            <h4>What we do</h4>
            <p>
              At the end of the day what we do as a company can be summed down
              to a single sentence. We solve problems using software.
            </p>

            <p>
              As a team we are collectively experts at machine learning,
              computer vision and software development. Some of us have research
              backgrounds, some of us are software experts. As a company we have
              worked in a wide range of industries such as Automobile, medicine,
              security, finance and art.
            </p>

            <p>
              We try to make our work speak for itself. Take a look at our <Link to="/cases">cases</Link>
            </p>
          </section>
        </main>
      </Page>
      <LogosBlock
        id="logos"
        logos={data.allLogosJson.edges.map((x) => x.node)}
      />
      <Location title={data.site.siteMetadata.title} id="location" />
      <Footer
        products={data.allProductsJson.edges.map((x) => x.node)}
        cases={data.allCasesJson.edges.map((x) => x.node)}
        title={data.site.siteMetadata.title}
      />
    </div>
  </div>
);

export default AboutUsPage;

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allLogosJson {
      edges {
        node {
          src
          alt
        }
      }
    }
    allCasesJson {
      edges {
        node {
          url
          shortTitle
        }
      }
    }
    allProductsJson {
      edges {
        node {
          url
          shortTitle
        }
      }
    }
  }
`;
